<template>
  <div class="aboutus-cnt3">
    <h6 class="h6">版权声明 - Copyright notice</h6>
    <div class="cnt">
      <p class="p">微微定网坚决遵守与支持我国有关版权的法律法规，坚持尊重与保护版权拥有者的合法权益，由于本网站对于非法转载、侵
权行为的发生不具备充分的监控能力，一旦出现侵权情况，用户及版权所有人可联系网站，经核查若情况属实，本网站将在
三日内删除相关作品。</p>
      <p class="p">微微定网对他人在网站上实施的此类侵权行为不承担法律责任，一切后果由上传用户本人承担。
在微微定网上传作品的用户，视为已同意此声明。</p>
      <h6 class="h5">隐私声明</h6>
      <p class="p">微微定网非常重视对于用户隐私的保护，特以此声明作为本网站保护用户个人隐私的承诺，请您在使用微微定网提供的服务
前，仔细阅读以下声明。</p>
      <p class="p">随着微微定网服务范围的不断扩大，隐私声明内容将随时更新，当更新后的隐私声明在网站公布后即有效代替原来的隐私声
明，欢迎您随时查看隐私说明。</p>
      <h6 class="h5">一、使用说明</h6>
      <p class="p">当用户在微微定网进行注册登记、购买商品、享受印刷及送货服务、留言评价等，需提供的个人资料包括：姓名、电话、送
货地址、邮箱地址、公司名称等内容多多印用户可以通过设定密码来保护账户和资料安全，用户应该妥善保管好个人密码，
不要与他人分享。</p>
      <h6 class="h5">二、用户权利</h6>
      <p class="p ps">用户对自己的个人资料享有以下权利：</p>
      <ul class="ul">
        <li><span>·</span> 随时登陆查询及阅览</li>
        <li><span>·</span> 随时进行补充或修改</li>
        <li><span>·</span> 随时请求删除</li>
        <li><span>·</span> 保护隐私原则</li>
      </ul>
      <p class="p">本网站将对用户提交的资料进行严格的管理及保护，承诺不会将用户个人资料用作它途。本站将使用相应的技术，防止用户
的个人资料丢失、被盗用或被篡改。未得到用户本人许可之前，本站不会将用户的任何个人信息提供给第三方。</p>
      <h6 class="h5">三、免责条款</h6>
      <p class="p ps">以下情况，本网站将不对用户隐私泄露承担责任：</p>
      <ul class="ul">
        <li><span>·</span> 用户本人同意让第三方共享资料</li>
        <li><span>·</span> 用户本人同意公开个人资料、购买的产品信息等</li>
        <li><span>·</span> 微微定网根据法律规定或政府相关政策提供用户的个人信息</li>
        <li><span>·</span> 任何由于黑客攻击、电脑病毒侵入或政府管制而造成的信息泄露</li>
        <li><span>·</span> 因不可抗力导致造成的任何后果</li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'aboutus-cnt3',
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.aboutus-cnt3 {
  width: 946px;float:right;background: #fff;border-radius: 5px;
  .h6 {
    height: 68px;border-bottom:1px solid #f2f2f2;font-weight: normal;font-size: 22px;line-height: 68px;padding-left:20px;
  }
  .cnt {
    padding:15px;
    .h5 {
      font-weight:normal;font-size: 16px;color:#555;margin-bottom:10px;
    }
    .p {
      font-size: 16px;color:#555555;line-height: 30px;margin-bottom:30px;
    }
    .ps {
      margin-bottom:10px;
    }
    .ul {
      margin-bottom:30px;
      li {
        font-size: 16px;color:#555;margin-bottom:10px;
        span {
          font-size: 36px;vertical-align: -6px;
        }
      }
    }
  }
}
</style>
